import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../components/Particle"; 
import Type from "../components/Home/Type"; 
import me from "../assets/me.jpeg"; 
import Projects from "../components/Projects/projects";
import Skillset from "../components/Skillset/SkillSet";
import ContactForm from '../components/Contact/Contact';
import Social from '../components/Contact/Social';
import About from "../components/Home/About";
import Tilt from "react-parallax-tilt";

const Home = () => {
  return (
    <section>
    
      <Container  fluid   className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
            
                <h1 className="heading-name">
                  <strong className="main-name">AMANDA FLECK</strong>
                </h1>
           
              <div class="typing ">
                   <h2 class="text-uppercase">Desenvolvedora</h2>
              </div>
             
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>
            <Col md={4} style={{ paddingBottom: 20 }}>
            <Tilt> 
              <img
                src={me}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
              </Tilt>
            </Col>
          </Row>
      
        </Container> 
      
      </Container>
        <About/> 
      
      <Container id="skills">
        <Skillset/>
       
      </Container>
      <Container id="projects">
           <Projects/>
      </Container>
      <Container id="contact">
          <Particle />
          <ContactForm />
          <Social />
      </Container>
    </section>
  );
};

export default Home;
