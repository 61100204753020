import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';
import Button from 'react-bootstrap/Button';
import './Contact.css';

const Contact = () => {
  const [state, handleSubmit] = useForm('xbljwrgw'); 

  if (state.succeeded) {
    return (
      <Container className="c-left">
        <h1>Obrigado por entrar em contato!</h1>
        <p>Entraremos em contato o mais breve possível.</p>
      </Container>
    );
  }

  return (
    <Container style={{ paddingTop: '20px'}}>
      <Row style={{ paddingTop: '80px' }}>
        <Col md={6} className="c-left">
          <h1>Entre em contato comigo</h1>
          <h1 className="yellow">Envie um e-mail!</h1>
        </Col>
        <Col md={6} className="c-right">
          <form onSubmit={handleSubmit}>
          <input
              id="nome"
              type="nome"
              name="Seu nome"
              className="user"
              placeholder="Seu nome"
              required
            />
                   
            <input
              id="email"
              type="email"
              name=" seu email"
              className="user"
              placeholder="Seu email"
              required
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />

          
            <textarea
              id="message"
              name="message"
              className="user"
              placeholder="Sua mensagem"
              required
            />
            <ValidationError prefix="Message" field="message" errors={state.errors} />

            <Button type="submit" className="button" disabled={state.submitting}>
              Enviar
            </Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
