import React from "react";
import Typewriter from "typewriter-effect";

const Type = () => {
    return (
        <Typewriter
          options={{
            strings: [
              "Desenvolvedora Full Stack",
            ],
            
          }}
        />
      )
}

export default Type