import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import Particle from "../Particle";


const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          PROJETOS<strong className="yellow"> </strong>
        </h1>
        <p style={{ color: "white" }}>
          Aqui estão alguns projetos que eu desenvolvi ou colaborei
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"https://www.sancuslabs.com/img/react.png"}
              isBlog={false}
              title="FloodAlert-REACT"
              description="Sistema de alerta e auxílio ao resgate em enchentes desenvolvido para participar do Hackathon 2024. Esta plataforma possui um design atrativos criado com React e Material-UI.  A plataforma possibilita que os usuários recebam avisos de perigo de acordo com a localização informada e possam enviar pedidos de avisos caso estejam em necessidade, como sem rota de saída por alagamento, esse aviso compartilha a sua localizado em tempo real a cada aviso que é enviado continuamente com um  intervalo.  

O back-end é alimentado por um servidor Node Express e utiliza o MySQL para a gestão do banco de dados."
              ghLink="https://github.com/amandita005/FloodAlert-HackathonREACT"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"https://www.sancuslabs.com/img/react.png"}
              isBlog={false}
              title="Armários Cimol-REACT"
              description="Uma aplicação web desenvolvida em React para o gerenciamento de armários do curso de informática do CIMOL. O sistema conta com login exclusivo para administradores, que podem visualizar e gerenciar armários organizados por prédio. Cada armário é identificado por cor e status, podendo estar Livre, Alugado ou Expirado. A aplicação permite CRUD completo, possibilitando adicionar, editar e excluir armários, além de atualizar seus status."
      
           ghLink="https://github.com/gabrieldssouza/sitearmarioscimolREACT.git"
            demoLink="https://frontarmarioscimol.vercel.app/buildings"
            />
          </Col>

          <Col md={4} className="project-card">
              <ProjectCard
                imgPath={"https://logos-download.com/wp-content/uploads/2016/09/Node_logo_NodeJS.png"}
                isBlog={false}
                title="QRCAM/api-NODEJS"
                description="A API do QRCAM é projetada para gerenciar o controle patrimonial de bens móveis utilizando QR Codes.

Ela oferece uma interface RESTful para operações relacionadas ao inventários, cadastro, localização, agrupamento e relatórios. Desenvolvida para integrar-se a aplicações mobile a API permite funções como: cadastro e consulta de Bens, gerenciamento de inventário, relatórios baseados no agrupamento. 


Desenvolvida com Node.js, utilizando Express.js como framework principal, e um banco  MySQL."
                ghLink="https://github.com/gabrieldssouza/apiqrcamNODEJS.git"
              />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"https://www.sancuslabs.com/img/react.png"}
              isBlog={false}
              title="QRCAM-REACTNATIVE"
              description="O Front-End do QRCAM  é uma aplicação mobile desenvolvida  em React Native, projetada para otimizar o controle patrimonial de bens móveis utilizando QR Codes. O aplicativo oferece uma interface intuitiva e moderna. Possuindo funcionalidades para 
Leitura de QR Codes com o uso da da câmera do dispositivo, cadastro e consulta de Bens, inventário e de gerar relatórios baseados no agrupamento ou no inventário dos bens."
              ghLink="https://github.com/gabrieldssouza/frontendqrcamREACTNATIVE.git"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"https://logos-download.com/wp-content/uploads/2016/09/Node_logo_NodeJS.png"}
              isBlog={false}
              title="ChatApi-NODE.JS"
             
              description="A API do Chat é uma solução que permite a comunicação entre usuários dentro de um chat, com suporte para mensagens de texto. Desenvolvida utilizando Node.js e Express, a API oferece endpoints seguros e escaláveis para gerenciar o envio, recebimento e armazenamento de mensagens, bem como a autenticação dos usuários."
              ghLink="https://github.com/amandita005/ChatApi-NODE.JS.git"
              demoLink=" https://documenter.getpostman.com/view/29717380/2sAXjKbYrC?authuser=0"  
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"https://www.sancuslabs.com/img/react.png"}
              isBlog={false}
              title="Chat-REACT"
              description="O Front-End do Chat é desenvolvido com React, projetado para proporcionar uma experiência fluida e intuitiva para comunicação. 
Design responsivo para facilitar a interação entre os usuários. Possui login seguro utilizando JWT para validação do usuário. Exibição de histórico de conversas."
              ghLink="https://github.com/amandita005/chatFrontEnd-REACT.git"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Projects