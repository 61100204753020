import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";

import me from '../../assets/me.jpeg'



const About = () => {
    return (
        <Container fluid className="home-about-section" id="about">
          <Container>
            <Row>
              <Col md={8} className="home-about-description">
                <h1 style={{ fontSize: "2.6em" }}>
                GOSTARIA DE ME <span className="yellow"> APRESENTAR </span>
                </h1>
                <p className="home-about-body">
                  Olá, meu nome é <span className="yellow">Amanda Fleck</span> e sou de <span className="yellow"> Taquara, Rio Grande do Sul. </span>
                  <br />   Sou desenvolvedora, concluinte do curso técnico de informática integrado ao ensino médio, 
                   onde obtive conhecimentos em desenvolvimento de projetos web e mobile, 
                   prototipação, UX/UI e programação. 
                <br />
                <br />Concluí o curso de Ciência da computação de Harvard, oferecido pela Fundação Escolar, e atualmente estou finalizando o curso de inglês avançado pelo SENAC, com previsão de conclusão entre maio e junho.
                <br />
                <br />  
               Como desenvolvedora Full-Stack, gosto de enfrentar e resolver novos desafios, tenho facilidade ao trabalhar em grupo e busco expandir continuamente minhas habilidades e conhecimentos.
                  <br />
                  <br />Tenho experiência em <b className="yellow"> Javascript, </b> além de saber utilizar linguagens de programação como C, Java, Python.
                  <br />
                  <br />Tenho  maior conhecimento e experiência prática em <b className="yellow"> React.js e React Native, Express e Node.js
                    </b> além de ter familiaridade com tecnologias de banco de dados, como <b className="yellow"> MySQL.
                    </b>
                  <i>
                  </i>
                 
                  <br />
                  <br />
                  Busco contribuir para o 
                  <i>
                    <b className="yellow"> desenvolvimento de novas tecnologias e produtos web </b>
                    , aplicando minhas <b className="yellow"> habilidades técnicas e de comunicação, </b> 
                     além de aproveitar as oportunidades de aprendizado que a empresa pode oferecer.  
                  </i>
                  <br />       
                  
                </p>
              </Col>
              <Col md={4} className="myAvtar">
                <Tilt>
                  <img src={me} className="img-fluid" alt="avatar" />
                </Tilt>
              </Col>
            </Row>  
          </Container>
        </Container>
      );
    
}

export default About