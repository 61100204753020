import React from "react";
import { Container } from "react-bootstrap";

import Particle from "../Particle";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";

const Skillset = () => {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        Utilizo as seguintes <strong className="yellow"> tecnologias </strong> 
        </h1>

        <Techstack />

        <h1 className="project-heading">
        Utilizo as seguintes <strong className="yellow">ferramentas</strong> 
        </h1>
        <Toolstack />

       
      </Container>
    </Container>
  )
}

export default Skillset